::placeholder {
  color: #a7a7a7;
  opacity: 1;
  font-weight: normal;
}

:-ms-input-placeholder {
  color: #a7a7a7;
  font-weight: normal;
}

::-moz-placeholder {
  color: #a7a7a7;
  font-weight: normal;
}

button {
  cursor: pointer;
  letter-spacing: .9px;
  border: none;
  border-radius: 50px;
  padding: 10px 25px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.btn_alpha:hover {
  background-color: #575757;
}

.btn_white {
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
}

.btn_white:hover {
  background-color: #fffad5;
  border-color: #f5e896;
}

.btn_grey_dark {
  background-color: #656565;
}

.btn_grey_dark:hover {
  background-color: #575757;
}

.btn_grey {
  background-color: #d5d4d4;
}

.btn_grey:hover {
  background-color: #c7c7c7;
}

.btn_blue {
  color: #fff;
  background-color: #40657e;
}

.btn_blue:hover {
  background-color: #37586d;
}

.btn_black {
  color: #fff;
  background-color: #000;
}

.btn_black:hover {
  background-color: #1d1d1d;
}

.dot_3_loader {
  margin: 0;
  padding: 5px 0;
  list-style: none;
  display: flex;
}

.dot_3_loader li {
  content: "";
  height: 7px;
  width: 7px;
  background: #959595;
  border-radius: 50%;
  animation: 1.5s infinite both blink;
  top: 0;
  left: 0;
}

.dot_3_loader li:nth-child(2) {
  margin-left: 4.5px;
  animation-delay: .2s;
}

.dot_3_loader li:nth-child(3) {
  margin-left: 4.5px;
  animation-delay: .4s;
}

@keyframes blink {
  0% {
    opacity: .1;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: .1;
  }
}

video {
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  background-color: #333;
  border-radius: 15px;
  position: absolute;
}

body {
  width: 100%;
  min-width: 360px;
  height: auto;
  background-color: #fff;
  margin: auto;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 15px;
}

body .app {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 14px 0;
  display: flex;
  position: relative;
}

body .app .wrapper {
  aspect-ratio: 16.5 / 8;
  min-width: 75%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

body .app .wrapper .col_3 {
  width: calc(60% - 7px);
  height: 100%;
  position: relative;
}

body .app .wrapper .col_3 .static_tv {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

body .app .wrapper .col_3 .static_tv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .2;
  border-radius: 15px;
  position: absolute;
}

body .app .wrapper .col_3 .static_tv .gradient_bg {
  width: 100%;
  height: 100%;
  opacity: .4;
  background: radial-gradient(circle, #9e9e9e 0%, #000 75%);
  border-radius: 15px;
  position: absolute;
}

body .app .wrapper .col_3 .status {
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: .15;
  z-index: 1;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-family: "Baloo 2", cursive;
  font-size: min(10.5vw, 50px);
  display: none;
  position: absolute;
}

body .app .wrapper .col_3 .status span {
  opacity: 1;
  animation: 1.8s infinite fade;
}

@keyframes fade {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

body .app .wrapper .col_3 .default {
  width: 100%;
  height: 100%;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

body .app .wrapper .col_3 .default .logo {
  height: 18%;
}

body .app .wrapper .col_3 .default p {
  letter-spacing: .9px;
  color: #fff;
  text-shadow: 1px 1px 1px #000;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  font-family: "Baloo 2", cursive;
  font-size: min(5vw, 22px);
  display: none;
}

body .app .wrapper .col_3 .default p span {
  margin: 0 7px;
}

body .app .wrapper .col_3 .default p span:first-child {
  width: 13px;
  height: 13px;
  background-color: #7cfc00;
  border-radius: 25px;
}

body .app .wrapper .col_3 .default p span:last-child {
  margin: 0;
}

body .app .wrapper .col_3 .default .info {
  color: #fff;
  letter-spacing: .9px;
  text-shadow: 1px 1px 1px #000;
  margin: 10px 0;
  font-family: "Baloo 2", cursive;
  font-size: min(4vw, 30px);
  font-weight: bold;
}

body .app .wrapper .col_3 .default .store_buttons {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

body .app .wrapper .col_3 .default .store_buttons a {
  margin: 0 13px;
  text-decoration: none;
}

body .app .wrapper .col_3 .default .store_buttons a button {
  min-width: 164px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

body .app .wrapper .col_3 .default .store_buttons a button img {
  width: 24px;
}

body .app .wrapper .col_3 .default .store_buttons a button .texts {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
  display: flex;
}

body .app .wrapper .col_3 .default .store_buttons a button .texts span:first-child {
  color: #959595;
  font-size: min(2vw, 12px);
}

body .app .wrapper .col_3 .default .store_buttons a button .texts span:last-child {
  color: #fff;
  font-size: min(2.8vw, 14px);
  font-weight: bold;
}

body .app .wrapper .col_3 .default .start_btn {
  letter-spacing: .5px;
  margin-top: 60px;
  padding: 13px 65px;
  font-size: min(3vw, 23px);
  font-weight: bold;
}

body .app .wrapper .col_3 .panel {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  z-index: 2;
  flex-direction: column;
  padding: 20px;
  display: none;
  position: absolute;
}

body .app .wrapper .col_3 .panel .top {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

body .app .wrapper .col_3 .panel .top .actions {
  flex-direction: row;
  align-items: center;
  display: flex;
}

body .app .wrapper .col_3 .panel .top .actions .icon_btn {
  width: min(11vw, 45px);
  height: min(11vw, 45px);
  cursor: pointer;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  display: flex;
}

body .app .wrapper .col_3 .panel .top .actions .icon_btn img {
  width: 100%;
}

body .app .wrapper .col_3 .panel .top .actions .icon_btn:hover {
  background-color: #656565;
}

body .app .wrapper .col_3 .panel .top .actions #report_btn {
  display: none;
}

body .app .wrapper .col_3 .panel .top .spacer {
  flex: 1;
  display: none;
}

body .app .wrapper .col_3 .panel .top .info {
  background: #0006;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  padding: 7px 12px;
  display: none;
}

body .app .wrapper .col_3 .panel .top .info img {
  height: 16px;
  border-radius: 4px;
}

body .app .wrapper .col_3 .panel .top .info span {
  color: #fff;
  letter-spacing: .4px;
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  font-size: 14px;
  display: flex;
}

body .app .wrapper .col_3 .panel .middle {
  height: 0;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  margin: 15px 0;
  display: flex;
}

body .app .wrapper .col_3 .panel .middle .chat_box {
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column-reverse;
  flex: 1;
  padding: 0 5px;
  display: none;
  overflow-y: scroll;
}

body .app .wrapper .col_3 .panel .middle .chat_box::-webkit-scrollbar {
  display: none;
}

body .app .wrapper .col_3 .panel .middle .chat_box .line {
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

body .app .wrapper .col_3 .panel .middle .chat_box .line img {
  height: 25px;
}

body .app .wrapper .col_3 .panel .middle .chat_box .line .msg_box {
  max-width: 80%;
  letter-spacing: .3px;
  border-radius: 12px;
  margin-left: 7px;
  padding: 9px 13px;
  font-size: 13px;
}

body .app .wrapper .col_3 .panel .middle .chat_box .line .me {
  color: #fff;
  background-color: #40657e;
}

body .app .wrapper .col_3 .panel .middle .chat_box .line .target {
  background-color: #fff;
}

body .app .wrapper .col_3 .panel .middle video {
  position: unset;
  width: 22%;
  height: auto;
  aspect-ratio: 1 / 1;
  border: 1px solid #656565;
  border-radius: 12px;
  display: none;
}

body .app .wrapper .col_3 .panel .bottom {
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

body .app .wrapper .col_3 .panel .bottom .c_input {
  background: #0006;
  border-radius: 25px;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-right: 15%;
  padding: 3px;
  display: none;
}

body .app .wrapper .col_3 .panel .bottom .c_input .clear {
  width: 20px;
  height: 20px;
  margin: 0 3px;
}

body .app .wrapper .col_3 .panel .bottom .c_input input {
  width: 0;
  color: #fff;
  letter-spacing: .5px;
  background: none;
  border-width: 0;
  flex: 1;
  margin: 0 2px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
}

body .app .wrapper .col_3 .panel .bottom .c_input input:focus {
  outline: none !important;
}

body .app .wrapper .col_3 .panel .bottom .c_input .icon_btn {
  width: 35px;
  height: 35px;
  cursor: pointer;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

body .app .wrapper .col_3 .panel .bottom .c_input .icon_btn img {
  width: 58%;
}

body .app .wrapper .col_3 .panel .bottom .next_btn {
  margin: 0 20px 15px;
  font-size: 17px;
}

body .app .wrapper .col_2 {
  width: calc(40% - 7px);
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

body .app .wrapper .col_2 .top {
  height: 54%;
  margin-bottom: 7px;
  position: relative;
}

body .app .wrapper .col_2 .top .preloader {
  width: 100%;
  height: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

body .app .wrapper .col_2 .top .preloader img {
  height: 25%;
}

body .app .wrapper .col_2 .top .warning {
  width: 100%;
  height: 100%;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
}

body .app .wrapper .col_2 .top .warning img {
  height: max(12%, 17px);
}

body .app .wrapper .col_2 .top .warning span {
  color: #fff;
  text-align: center;
  margin-top: 12px;
  padding: 0 10px;
  font-size: min(1.7vw, 15px);
}

body .app .wrapper .col_2 .bottom {
  height: 46%;
  background-color: #f1f0f0;
  border-radius: 12px;
  margin-top: 7px;
  position: relative;
}

body .app .wrapper .col_2 .bottom .sticker {
  height: 75%;
  width: 100%;
  color: #e3e3e3;
  letter-spacing: 1px;
  z-index: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Baloo 2", cursive;
  font-size: min(2.7vw, 28px);
  display: flex;
  position: absolute;
}

body .app .wrapper .col_2 .bottom .sticker img {
  height: 37%;
}

body .app .wrapper .col_2 .bottom .chat_box {
  height: calc(100% - 10px);
  width: calc(100% - 20px);
  z-index: 1;
  flex-direction: column;
  padding: 0 10px 10px;
  display: flex;
  position: absolute;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-direction: column-reverse;
  flex: 1;
  margin-bottom: 8px;
  padding: 0 5px;
  display: flex;
  overflow-y: scroll;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body::-webkit-scrollbar {
  display: none;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .target {
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .target img {
  height: 25px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .target .msg_box {
  max-width: 70%;
  letter-spacing: .3px;
  background-color: #fff;
  border-radius: 12px;
  margin-left: 7px;
  padding: 9px 13px;
  font-size: 13px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .target .msg_box span {
  color: #000;
  letter-spacing: .4px;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  display: flex;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .target .msg_box span ul {
  margin-left: 4px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .target .msg_box span:last-child {
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
  font-weight: normal;
  display: flex;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .target .msg_box span:last-child .c_flag {
  height: 16px;
  border-radius: 4px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .target .msg_box span:last-child .c_name {
  flex-direction: row;
  align-items: center;
  margin-left: 5px;
  font-size: 12px;
  display: flex;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .me {
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .me .msg_box {
  max-width: 70%;
  letter-spacing: .3px;
  color: #fff;
  background-color: #40657e;
  border-radius: 12px;
  margin-right: 7px;
  padding: 9px 13px;
  font-size: 13px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .me img {
  height: 30px;
  border: 1px solid #c7c7c7;
  border-radius: 25px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .system {
  width: 97%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .system img {
  height: 25px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_body .system .msg_box {
  letter-spacing: .3px;
  background-color: #fffad5;
  border-radius: 12px;
  margin-left: 7px;
  padding: 9px 13px;
  font-size: 13px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_input {
  height: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 25px;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px;
  display: flex;
}

body .app .wrapper .col_2 .bottom .chat_box .c_input input {
  color: #363636;
  letter-spacing: .5px;
  background: none;
  border-width: 0;
  flex: 1;
  margin: 2px 6px;
  padding: 0 7px;
  font-family: Poppins, sans-serif;
  font-size: 13px;
}

body .app .wrapper .col_2 .bottom .chat_box .c_input input:focus {
  outline: none !important;
}

body .app .wrapper .col_2 .bottom .chat_box .c_input .icon_btn {
  width: min(3.5vw, 38px);
  height: min(3.5vw, 38px);
  cursor: pointer;
  border-radius: 25px;
  justify-content: center;
  align-items: center;
  display: flex;
}

body .app .wrapper .col_2 .bottom .chat_box .c_input .icon_btn img {
  width: 58%;
}

body .app .wrapper .col_2 .bottom .chat_box .input_disabled {
  background-color: #f1f0f0;
}

body .documents {
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

body .documents .doc_head {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eaeaea;
  justify-content: center;
  display: flex;
}

body .documents .doc_head .wrapper {
  width: 75%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

body .documents .doc_head .wrapper img {
  cursor: pointer;
  height: 90px;
  width: auto;
}

body .documents .doc_head .wrapper h1 {
  letter-spacing: 1.01px;
  text-align: center;
  margin: -5px 0 7px;
  font-size: 24px;
}

body .documents .doc_body {
  width: 75%;
  padding-bottom: 10px;
}

body .documents .doc_body h2 {
  margin: 30px 0;
}

body .documents .doc_body h2:first-child {
  font-size: 25px;
}

body .documents .doc_body .store_buttons {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  display: flex;
}

body .documents .doc_body .store_buttons a {
  margin: 15px 0;
  text-decoration: none;
}

body .documents .doc_body .store_buttons a button {
  min-width: 250px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

body .documents .doc_body .store_buttons a button img {
  width: 30px;
}

body .documents .doc_body .store_buttons a button .texts {
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  display: flex;
}

body .documents .doc_body .store_buttons a button .texts span:first-child {
  color: #959595;
  font-size: min(3vw, 15px);
}

body .documents .doc_body .store_buttons a button .texts span:last-child {
  color: #fff;
  font-size: min(4vw, 17px);
  font-weight: bold;
}

body .footer {
  width: 100%;
  height: auto;
  justify-content: center;
  display: flex;
  position: relative;
}

body .footer .wrapper {
  width: 75%;
  flex-direction: column;
  display: flex;
}

body .footer .wrapper h2 {
  letter-spacing: .8px;
  font-family: "Baloo 2", cursive;
  font-size: 30px;
  font-weight: bold;
}

body .footer .wrapper h2 small {
  color: #656565;
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

body .footer .wrapper p {
  color: #656565;
  margin: 0;
  font-size: 14px;
  line-height: 25px;
}

body .footer .wrapper ul {
  flex-direction: row;
  align-items: baseline;
  padding: 0;
  list-style: none;
  display: flex;
}

body .footer .wrapper ul li {
  margin-right: 12px;
}

body .footer .wrapper ul li a {
  color: #656565;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

body .footer .wrapper ul li a:hover {
  color: #000;
  text-decoration: underline;
}

body .footer .wrapper ul .sep {
  color: #656565;
  font-size: 11px;
}

body .footer .wrapper p:last-child {
  font-size: 12px;
}

body .footer .wrapper .store_socials {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  display: flex;
}

body .footer .wrapper .store_socials .store {
  flex-direction: column;
  display: flex;
}

body .footer .wrapper .store_socials .store .top a {
  text-decoration: none;
}

body .footer .wrapper .store_socials .store .top a img {
  cursor: pointer;
  margin-right: 10px;
}

body .footer .wrapper .store_socials .store a:last-child {
  color: #656565;
  margin-top: 15px;
  font-size: 13px;
  text-decoration: none;
}

body .footer .wrapper .store_socials .social a {
  text-decoration: none;
}

body .footer .wrapper .store_socials .social a img {
  height: 40px;
  margin-right: 12px;
}

body .popup_content {
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #00000059;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

body .popup_content .gender {
  width: 400px;
  background-color: #fff;
  border-radius: 15px;
  flex-direction: column;
  margin-bottom: -350vh;
  padding: 20px;
  display: flex;
  position: absolute;
  box-shadow: 0 1px 5px #00000026;
}

body .popup_content .gender h2 {
  text-align: center;
  margin: 0;
  font-size: 17px;
}

body .popup_content .gender .warning {
  color: #cd492d;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

body .popup_content .gender .selector {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

body .popup_content .gender .selector .box {
  cursor: pointer;
  border: 1px solid #eaeaea;
  border-radius: 7px;
  flex-direction: column;
  align-items: center;
  margin: 15px 25px 0;
  padding: 10px 18px;
  display: flex;
}

body .popup_content .gender .selector .box:hover {
  background-color: #fffad5;
  border-color: #f5e896;
}

body .popup_content .gender .selector .box img {
  width: 65px;
}

body .popup_content .gender .selector .box span {
  margin-top: 7px;
  font-size: 13px;
  font-weight: bold;
}

body .popup_content .gender .selector .box_selected {
  background-color: #fffad5;
}

body .popup_content .gender button {
  margin: 30px auto 10px;
  padding: 12px 75px;
}

body .popup_content .authentication {
  width: 650px;
  color: #fff;
  flex-direction: row;
  margin-bottom: -350vh;
  display: flex;
  position: absolute;
  box-shadow: 0 1px 5px #00000026;
}

body .popup_content .authentication .left {
  width: 40%;
  background-color: #37586d;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  flex-direction: column;
  align-items: center;
  padding: 40px 15px;
  display: flex;
}

body .popup_content .authentication .left h2 {
  letter-spacing: .9px;
  text-align: center;
  margin: 10px 0;
  font-family: "Baloo 2", cursive;
  font-size: 20px;
}

body .popup_content .authentication .left span {
  text-align: center;
  color: #eaeaea;
  margin: 0 20px;
  font-size: 13px;
}

body .popup_content .authentication .left .qr_code_img {
  width: 50%;
  border: 7px solid #fff;
  border-radius: 8px;
  margin: 15px 0;
}

body .popup_content .authentication .left .stores {
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

body .popup_content .authentication .left .stores a {
  margin: 0 15px;
  text-decoration: none;
}

body .popup_content .authentication .left .stores a img {
  height: 23px;
}

body .popup_content .authentication .left .stores a:last-child img {
  height: 20px;
  margin-top: 3px;
}

body .popup_content .authentication .right {
  width: 60%;
  background-color: #363636;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  flex-direction: column;
  align-items: center;
  padding: 40px 15px;
  display: flex;
}

body .popup_content .authentication .right h2 {
  letter-spacing: .9px;
  text-align: center;
  margin: 10px 0;
  font-family: "Baloo 2", cursive;
  font-size: 32px;
}

body .popup_content .authentication .right .info {
  text-align: center;
  margin: 0 20px;
  font-size: 14px;
}

body .popup_content .authentication .right .spacer {
  flex: 1;
}

body .popup_content .authentication .right #google_btn {
  margin-top: 20px;
}

body .popup_content .authentication .right #facebook_btn {
  width: 197px;
  height: auto;
  cursor: pointer;
  -o-transition: all .1s ease-in;
  background-color: #1877f2;
  border-radius: 25px;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  padding: 5px;
  transition: all .1s ease-in;
  display: flex;
}

body .popup_content .authentication .right #facebook_btn:hover {
  background-color: #5194ee;
}

body .popup_content .authentication .right #facebook_btn img {
  height: 31px;
  justify-content: center;
  align-items: center;
  display: flex;
}

body .popup_content .authentication .right #facebook_btn span {
  text-align: left;
  letter-spacing: .9px;
  flex: 1;
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
}

body .popup_content .authentication .right #no_login_btn {
  cursor: pointer;
  margin-top: 20px;
  font-size: 13px;
}

body .popup_content .authentication .right #no_login_btn:hover {
  text-decoration: underline;
}

body .popup_content .authentication .right .auth_process {
  text-align: center;
  color: #ccc;
  width: 75%;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  display: none;
}

body .popup_content .authentication .right .auth_process .dot_3_loader {
  margin-bottom: 10px;
}

body .popup_content .authentication .right .auth_process .dot_3_loader li {
  width: 12px;
  height: 12px;
  margin: 0 4px;
}

body .permission_content {
  width: 100%;
  height: 100%;
  z-index: 110;
  background: #00000059;
  justify-content: flex-end;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

body .permission_content .box {
  flex-direction: column;
  align-items: flex-end;
  display: flex;
  position: absolute;
  top: 25px;
  right: 13%;
}

body .permission_content .box .top {
  flex-direction: row;
  align-items: flex-end;
  display: flex;
}

body .permission_content .box .top img:first-child {
  height: 80px;
  margin: 0 12px;
}

body .permission_content .box .top img:last-child {
  height: 120px;
  margin-bottom: 30px;
}

body .permission_content .box .bottom {
  color: #fff;
  letter-spacing: 1px;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
  display: flex;
}

body .notify_content {
  width: auto;
  height: auto;
  z-index: 150;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 15px;
  right: 15px;
}

body .notify_content .notify_box {
  width: 290px;
  height: auto;
  cursor: pointer;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  padding: 20px 25px;
  display: flex;
}

body .notify_content .notify_box img {
  height: 30px;
}

body .notify_content .notify_box span {
  color: #fff;
  margin-left: 15px;
  font-size: 14px;
}

body .notify_content .error {
  background-color: #de7058;
}

body .notify_content .report {
  background-color: #40657e;
}

@media only screen and (max-width: 785px) {
  body .app {
    margin: 0;
  }

  body .app .wrapper {
    aspect-ratio: 1 / 1;
  }

  body .app .wrapper .col_3 {
    width: 100%;
  }

  body .app .wrapper .col_3 video, body .app .wrapper .col_3 .status {
    border-radius: 0;
  }

  body .app .wrapper .col_3 .panel .top .actions {
    flex-direction: row-reverse;
  }

  body .app .wrapper .col_3 .panel .top .actions .icon_btn {
    margin-left: 0;
    margin-right: 25px;
  }

  body .app .wrapper .col_3 .panel .top .spacer {
    display: block;
  }

  body .app .wrapper .col_3 .panel .top .info, body .app .wrapper .col_3 .panel .middle .chat_box {
    display: flex;
  }

  body .app .wrapper .col_3 .panel .middle video {
    border-radius: 12px;
    display: flex;
  }

  body .app .wrapper .col_3 .panel .bottom .c_input {
    display: flex;
  }

  body .app .wrapper .col_3 .panel .bottom .next_btn {
    margin: 0;
    font-size: 14px;
  }

  body .app .wrapper .col_2 {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  body .documents .doc_head .wrapper, body .documents .doc_body, body .footer .wrapper {
    width: 85%;
  }

  body .footer .wrapper ul {
    flex-wrap: wrap;
  }

  body .footer .wrapper .store_socials .social {
    flex-basis: 100%;
    margin-top: 25px;
  }

  body .popup_content {
    align-items: flex-end;
  }

  body .popup_content .gender {
    width: calc(100% - 40px);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  body .popup_content .authentication {
    width: 100%;
  }

  body .popup_content .authentication .left {
    display: none;
  }

  body .popup_content .authentication .right {
    width: 100%;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 0;
    padding: 20px 15px;
  }

  body .popup_content .authentication .right .info {
    margin-bottom: 15px;
  }

  body .permission_content .box {
    align-items: center;
    left: 0;
    right: 0;
  }

  body .notify_content {
    width: 100%;
    top: 0;
    right: 0;
  }

  body .notify_content .notify_box {
    width: 100%;
    margin: 15px;
  }
}

/*# sourceMappingURL=index.f0b9c46d.css.map */
