$font_poppins: 'Poppins', sans-serif;
$font_baloo: 'Baloo 2', cursive;

$color_white: #fff;
$color_white_dark_1: #e3e3e3;

$color_grey_light_6: #f1f0f0;
$color_grey_light_5: #eaeaea;
$color_grey_light_4: #d5d4d4;
$color_grey_light_3: #c7c7c7;
$color_grey_light_0: #dddddd;
$color_grey: #ccc;
$color_grey_dark_1: #a7a7a7;
$color_grey_dark_2: #959595;
$color_grey_dark_3: #656565;
$color_grey_dark_4: #575757;

$color_black_light_2: #363636;
$color_black_light_1: #333;
$color_black: #000;
$color_black_light_0: #1d1d1d;

$color_red: #de7058;
$color_red_dark_1: #cd492d;

$color_lime: #7cfc00;

$color_yellow_light_4: #fffad5;
$color_yellow_light_2: #f5e896;

$color_blue: #40657e;
$color_blue_dark_1: #37586d;

$color_light_blue_light_1: #5194ee;
$color_light_blue: #1877f2;

//MATERIALS AREA

::placeholder {
  color: $color_grey_dark_1;
  opacity: 1;
  font-weight: normal;
}

:-ms-input-placeholder {
  color: $color_grey_dark_1;
  font-weight: normal;
}

::-ms-input-placeholder {
  color: $color_grey_dark_1;
  font-weight: normal;
}

//######## BUTTONS ########
button {
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  border: none;
  font-family: $font_poppins;
  letter-spacing: 0.9px;
  font-size: 14px;
  padding: 10px 25px;
}


.btn_alpha {
  &:hover {
    background-color: $color_grey_dark_4;
  }
}

.btn_white {
  color: $color_black;
  border: 1px solid $color_grey_light_0;
  background-color: $color_white;

  &:hover {
    border-color: $color_yellow_light_2;
    background-color: $color_yellow_light_4;
  }
}

.btn_grey_dark {
  background-color: $color_grey_dark_3;

  &:hover {
    background-color: $color_grey_dark_4;
  }
}

.btn_grey {
  background-color: $color_grey_light_4;

  &:hover {
    background-color: $color_grey_light_3;
  }
}

.btn_blue {
  color: $color_white;
  background-color: $color_blue;

  &:hover {
    background-color: $color_blue_dark_1;
  }
}

.btn_black {
  color: $color_white;
  background-color: $color_black;

  &:hover {
    background-color: $color_black_light_0;
  }
}

//######## BUTTONS ########

.dot_3_loader {
  padding: 5px 0;
  display: flex;
  list-style: none;
  margin: 0;

  li {
    content: '';
    animation: blink 1.5s infinite;
    animation-fill-mode: both;
    height: 7px;
    width: 7px;
    background: $color_grey_dark_2;
    left: 0;
    top: 0;
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: .2s;
      margin-left: 3px * 1.5;
    }

    &:nth-child(3) {
      animation-delay: .4s;
      margin-left: 3px * 1.5;
    }
  }

  @keyframes blink {
    0% {
      opacity: .1;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: .1;
    }
  }
}

video {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: $color_black_light_1;
  border-radius: 15px;
  object-fit: cover;
  //transform: rotateY(180deg);
  /*
  transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  */
}

//MATERIALS AREA

body {
  margin: auto;
  padding: 0;
  background-color: $color_white;
  font-family: $font_poppins;
  width: 100%;
  min-width: 360px;
  height: auto;
  font-size: 15px;

  .app {
    position: relative;
    display: flex;
    width: 100%;
    margin: 14px 0;
    justify-content: center;
    align-items: center;

    .wrapper {
      aspect-ratio: 16.5/8;
      display: flex;
      min-width: 75%;
      justify-content: space-between;
      flex-direction: row;

      .col_3 {
        position: relative;
        width: calc(60% - 7px);
        height: 100%;

        .static_tv {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;

          img {
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            object-fit: cover;
            opacity: 0.2;
          }

          .gradient_bg {
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 15px;
            opacity: 0.4;
            background: $color_black;
            background: radial-gradient(circle, rgb(158, 158, 158) 0%, rgba(0, 0, 0, 1) 75%);
          }
        }

        .status {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 15px;
          display: none; /*flex*/
          align-items: center;
          justify-content: center;
          font-size: min(10.5vw, 50px);
          font-family: $font_baloo;
          color: $color_white;
          opacity: 0.15;
          z-index: 1;

          span {
            opacity: 1;
            animation: fade 1.8s infinite;
          }

          @keyframes fade {
            0%, 100% {
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
          }
        }

        .default {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 2;
          display: flex; /*flex*/
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .logo {
            height: 18%;
          }

          p {
            margin: 10px 0;
            display: none; /*flex*/
            flex-direction: row;
            align-items: center;
            font-family: $font_baloo;
            font-size: min(5vw, 22px);
            letter-spacing: 0.9px;
            color: $color_white;
            text-shadow: 1px 1px 1px $color_black;

            span {
              margin: 0 7px;

              &:first-child {
                border-radius: 25px;
                width: 13px;
                height: 13px;
                background-color: $color_lime;
              }

              &:last-child {
                margin: 0;
              }
            }
          }

          .info {
            margin: 10px 0;
            font-family: $font_baloo;
            color: $color_white;
            font-size: min(4vw, 30px);
            letter-spacing: 0.9px;
            font-weight: bold;
            text-shadow: 1px 1px 1px $color_black;
          }

          .store_buttons {
            margin-top: 10px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            a {
              text-decoration: none;
              margin: 0 13px;

              button {
                min-width: 164px;
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  width: 24px;
                }

                .texts {
                  margin-left: 12px;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;

                  span:first-child {
                    font-size: min(2vw, 12px);
                    color: $color-grey_dark_2;
                  }

                  span:last-child {
                    font-size: min(2.8vw, 14px);
                    font-weight: bold;
                    color: $color_white;
                  }
                }
              }
            }
          }

          .start_btn {
            margin-top: 60px;
            padding: 13px 65px;
            font-size: min(3vw, 23px);
            font-weight: bold;
            letter-spacing: 0.5px;
          }
        }

        .panel {
          position: absolute;
          width: calc(100% - 40px);
          height: calc(100% - 40px);
          z-index: 2;
          display: none; /*flex*/
          flex-direction: column;
          padding: 20px;

          .top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .actions {
              display: flex;
              flex-direction: row;
              align-items: center;

              .icon_btn {
                border-radius: 25px;
                width: min(11vw, 45px);
                height: min(11vw, 45px);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 25px;

                img {
                  width: 100%;
                }

                &:hover {
                  background-color: $color_grey_dark_3;
                }
              }

              #report_btn {
                display: none;
              }

            }

            .spacer {
              flex: 1;
              display: none;
            }

            .info {
              display: none; /*flex*/
              flex-direction: row;
              align-items: center;
              padding: 7px 12px;
              border-radius: 12px;
              background: rgba(0, 0, 0, 0.4);

              img {
                height: 16px;
                border-radius: 4px;
              }

              span {
                margin-left: 5px;
                font-size: 14px;
                display: flex;
                flex-direction: row;
                align-items: center;
                color: $color_white;
                letter-spacing: 0.4px;
              }
            }

          }

          .middle {
            height: 0;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin: 15px 0;
            flex: 1;

            .chat_box {
              display: none; /*flex*/
              flex: 1;
              height: 100%;
              padding: 0 5px;
              overflow-y: scroll;
              -ms-overflow-style: none;
              scrollbar-width: none;
              flex-direction: column-reverse;

              &::-webkit-scrollbar {
                display: none;
              }

              .line {
                width: 100%;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  height: 25px;
                }

                .msg_box {
                  max-width: 80%;
                  margin-left: 7px;
                  padding: 9px 13px;
                  border-radius: 12px;
                  font-size: 13px;
                  letter-spacing: 0.3px;
                }

                .me {
                  background-color: $color_blue;
                  color: $color_white;
                }

                .target {
                  background-color: $color_white;
                }

              }

            }

            video {
              display: none; /*flex*/
              position: unset;
              width: 22%;
              height: auto;
              aspect-ratio: 1/1;
              border: 1px solid $color_grey_dark_3;
              border-radius: 12px;
            }

          }

          .bottom {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;

            .c_input {
              display: none; /*flex*/
              padding: 3px;
              flex: 1;
              margin-right: 15%;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              border-radius: 25px;
              background: rgba(0, 0, 0, 0.4);

              .clear {
                width: 20px;
                height: 20px;
                margin: 0 3px;
              }

              input {
                flex: 1;
                font-size: 13px;
                margin: 0 2px;
                width: 0;
                color: $color_white;
                font-family: $font_poppins;
                letter-spacing: 0.5px;
                border-width: 0;
                background: none;

                &:focus {
                  outline: none !important;
                }
              }

              .icon_btn {
                border-radius: 25px;
                width: 35px;
                height: 35px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 58%;
                }
              }

            }

            .next_btn {
              font-size: 17px;
              margin: 0 20px 15px;
            }


          }
        }

      }

      .col_2 {
        width: calc(40% - 7px);
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top {
          position: relative;
          height: 54%;
          margin-bottom: 7px;


          .preloader {
            position: absolute;
            display: flex; /*flex*/
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            z-index: 1;

            img {
              height: 25%;
            }
          }

          .warning {
            position: absolute;
            width: 100%;
            height: 100%;
            display: none; /*flex*/
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 1;

            img {
              height: max(12%, 17px);
            }

            span {
              margin-top: 12px;
              color: $color_white;
              text-align: center;
              padding: 0 10px;
              font-size: min(1.7vw, 15px);
            }
          }

        }

        .bottom {
          position: relative;
          height: 46%;
          margin-top: 7px;
          border-radius: 12px;
          background-color: $color_grey_light_6;

          .sticker {
            position: absolute;
            height: 75%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-family: $font_baloo;
            color: $color_white_dark_1;
            font-size: min(2.7vw, 28px);
            letter-spacing: 1px;
            z-index: 0;

            img {
              height: 37%;
            }
          }

          .chat_box {
            position: absolute;
            height: calc(100% - 10px);
            width: calc(100% - 20px);
            display: flex;
            flex-direction: column;
            padding: 0 10px 10px;
            z-index: 1;

            .c_body {
              flex: 1;
              margin-bottom: 8px;
              padding: 0 5px;
              overflow-y: scroll;
              -ms-overflow-style: none;
              scrollbar-width: none;
              display: flex;
              flex-direction: column-reverse;

              &::-webkit-scrollbar {
                display: none;
              }

              .target {
                width: 100%;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  height: 25px;
                }

                .msg_box {
                  max-width: 70%;
                  margin-left: 7px;
                  background-color: $color_white;
                  padding: 9px 13px;
                  border-radius: 12px;
                  font-size: 13px;
                  letter-spacing: 0.3px;

                  span {
                    color: $color_black;
                    font-weight: bold;
                    letter-spacing: 0.4px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    ul {
                      margin-left: 4px;
                    }

                    &:last-child {
                      margin-top: 2px;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      font-weight: normal;

                      .c_flag {
                        height: 16px;
                        border-radius: 4px;
                      }

                      .c_name {
                        margin-left: 5px;
                        font-size: 12px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                      }

                    }
                  }

                }

              }

              .me {
                width: 100%;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                .msg_box {
                  max-width: 70%;
                  margin-right: 7px;
                  background-color: $color_blue;
                  padding: 9px 13px;
                  border-radius: 12px;
                  font-size: 13px;
                  letter-spacing: 0.3px;
                  color: $color_white;

                }

                img {
                  height: 30px;
                  border: 1px solid $color_grey_light_3;
                  border-radius: 25px;
                }
              }

              .system {
                width: 97%;
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  height: 25px;
                }

                .msg_box {
                  margin-left: 7px;
                  background-color: $color_yellow_light_4;
                  padding: 9px 13px;
                  border-radius: 12px;
                  font-size: 13px;
                  letter-spacing: 0.3px;
                }
              }

            }

            .c_input {
              height: auto;
              display: flex;
              padding: 3px;
              flex-direction: row;
              justify-content: space-between;
              border-radius: 25px;
              border: 1px solid $color_grey_light_0;
              background-color: $color_white;

              input {
                flex: 1;
                margin: 2px 6px;
                padding: 0 7px;
                font-size: 13px;
                color: $color_black_light_2;
                font-family: $font_poppins;
                letter-spacing: 0.5px;
                border-width: 0;
                background: none;

                &:focus {
                  outline: none !important;
                }
              }

              .icon_btn {
                border-radius: 25px;
                width: min(3.5vw, 38px);
                height: min(3.5vw, 38px);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 58%;
                }
              }
            }

            .input_disabled {
              background-color: $color_grey_light_6;
            }

          }


        }
      }

    }

  }

  .documents {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .doc_head {
      width: 100%;
      height: auto;
      border-bottom: 1px solid $color_grey_light_5;
      display: flex;
      justify-content: center;

      .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 75%;

        img {
          cursor: pointer;
          height: 90px;
          width: auto;
        }

        h1 {
          font-size: 24px;
          margin: -5px 0 7px;
          letter-spacing: 1.01px;
          text-align: center;
        }
      }
    }

    .doc_body {
      width: 75%;
      padding-bottom: 10px;

      h2 {
        margin: 30px 0;
      }

      h2:first-child {
        font-size: 25px;
      }

      .store_buttons {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
          text-decoration: none;
          margin: 15px 0;

          button {
            min-width: 250px;
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
              width: 30px;
            }

            .texts {
              margin-left: 20px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              span:first-child {
                font-size: min(3vw, 15px);
                color: $color-grey_dark_2;
              }

              span:last-child {
                font-size: min(4vw, 17px);
                font-weight: bold;
                color: $color_white;
              }
            }
          }
        }
      }
    }

  }

  .footer {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    .wrapper {
      width: 75%;
      display: flex;
      flex-direction: column;

      h2 {
        font-size: 30px;
        letter-spacing: 0.8px;
        font-family: $font_baloo;
        font-weight: bold;

        small {
          font-family: $font_poppins;
          font-size: 14px;
          color: $color_grey_dark_3;
        }
      }

      p {
        margin: 0;
        font-size: 14px;
        line-height: 25px;
        color: $color_grey_dark_3;
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        align-items: baseline;

        li {
          margin-right: 12px;

          a {
            text-decoration: none;
            color: $color_grey_dark_3;
            font-weight: bold;
            font-size: 14px;

            &:hover {
              text-decoration: underline;
              color: $color_black;
            }
          }
        }

        .sep {
          font-size: 11px;
          color: $color_grey_dark_3;
        }
      }

      p:last-child {
        font-size: 12px;
      }

      .store_socials {
        margin: 30px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .store {
          display: flex;
          flex-direction: column;

          .top {
            a {
              text-decoration: none;

              img {
                margin-right: 10px;
                cursor: pointer;
              }
            }
          }

          a:last-child {
            margin-top: 15px;
            text-decoration: none;
            color: $color_grey_dark_3;
            font-size: 13px;
          }

        }

        .social {
          a {
            text-decoration: none;

            img {
              height: 40px;
              margin-right: 12px;
            }
          }
        }

      }

    }
  }

  //ABSOLUTE AREA
  .popup_content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    z-index: 100;
    display: none; /*flex*/
    background: rgba(0, 0, 0, 0.35);

    .gender {
      position: absolute;
      background-color: $color_white;
      padding: 20px;
      border-radius: 15px;
      width: 400px;
      margin-bottom: -350vh;
      display: flex;
      flex-direction: column;
      box-shadow: rgb(0 0 0 / 15%) 0 1px 5px;

      h2 {
        margin: 0;
        font-size: 17px;
        text-align: center;
      }

      .warning {
        margin-top: 10px;
        font-size: 14px;
        color: $color_red_dark_1;
        text-align: center;
      }

      .selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        .box {
          margin: 15px 25px 0;
          padding: 10px 18px;
          border-radius: 7px;
          border: 1px solid $color_grey_light_5;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;

          &:hover {
            border-color: $color_yellow_light_2;
            background-color: $color_yellow_light_4;
          }

          img {
            width: 65px;
          }

          span {
            margin-top: 7px;
            font-size: 13px;
            font-weight: bold;
          }
        }

        .box_selected {
          background-color: $color_yellow_light_4;
        }
      }

      button {
        margin: 30px auto 10px;
        padding: 12px 75px;
      }
    }

    .authentication {
      position: absolute;
      width: 650px;
      margin-bottom: -350vh;
      display: flex;
      flex-direction: row;
      box-shadow: rgb(0 0 0 / 15%) 0 1px 5px;
      color: $color_white;

      .left {
        width: 40%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        background-color: $color_blue_dark_1;
        padding: 40px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-family: $font_baloo;
          letter-spacing: 0.9px;
          text-align: center;
          font-size: 20px;
          margin: 10px 0;
        }

        span {
          font-size: 13px;
          text-align: center;
          margin: 0 20px;
          color: $color_grey_light_5;
        }

        .qr_code_img {
          width: 50%;
          border-radius: 8px;
          border: 7px solid $color_white;
          margin: 15px 0;
        }

        .stores {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;

          a {
            text-decoration: none;
            margin: 0 15px;

            img {
              height: 23px;
            }
          }

          a:last-child {
            img {
              margin-top: 3px;
              height: 20px;
            }
          }
        }
      }

      .right {
        width: 60%;
        background-color: $color_black_light_2;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 40px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          font-family: $font_baloo;
          letter-spacing: 0.9px;
          text-align: center;
          font-size: 32px;
          margin: 10px 0;
        }

        .info {
          font-size: 14px;
          text-align: center;
          margin: 0 20px;
        }

        .spacer {
          flex: 1;
        }

        #google_btn {
          margin-top: 20px;
        }

        #facebook_btn {
          margin-top: 20px;
          width: 197px;
          height: auto;
          padding: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: $color_light_blue;
          border-radius: 25px;
          cursor: pointer;
          -moz-transition: all .1s ease-in;
          -o-transition: all .1s ease-in;
          -webkit-transition: all .1s ease-in;
          transition: all .1s ease-in;

          &:hover {
            background-color: $color_light_blue_light_1;
          }

          img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 31px;
          }

          span {
            flex: 1;
            text-align: left;
            margin-left: 8px;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.9px;
          }
        }

        #no_login_btn {
          margin-top: 20px;
          font-size:13px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        .auth_process {
          display: none;
          flex-direction: column;
          align-items: center;
          text-align: center;
          font-size: 13px;
          color: $color_grey;
          width: 75%;

          .dot_3_loader {
            margin-bottom: 10px;

            li {
              width: 12px;
              height: 12px;
              margin: 0 4px;
            }
          }
        }

      }
    }

  }

  .permission_content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: flex-end;
    z-index: 110;
    display: none; /*flex*/
    background: rgba(0, 0, 0, 0.35);

    .box {
      position: absolute;
      top: 25px;
      right: 13%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .top {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        img {
          &:first-child {
            margin: 0 12px;
            height: 80px;
          }

          &:last-child {
            height: 120px;
            margin-bottom: 30px;
          }
        }
      }

      .bottom {
        margin-top: 20px;
        color: $color_white;
        font-size: 25px;
        font-weight: bold;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        text-align: center;
      }
    }

  }

  .notify_content {
    position: fixed;
    width: auto;
    height: auto;
    right: 15px;
    top: 15px;
    align-items: center;
    justify-content: center;
    z-index: 150;
    display: none; /*flex*/

    .notify_box {
      width: 290px;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px 25px;
      border-radius: 12px;
      cursor: pointer;

      img {
        height: 30px;
      }

      span {
        margin-left: 15px;
        color: $color_white;
        font-size: 14px;
      }
    }

    .error {
      background-color: $color_red;
    }

    .report {
      background-color: $color_blue;
    }


  }

  //ABSOLUTE AREA
}

@media only screen and (max-width: 785px) {
  body {
    .app {
      margin: 0;

      .wrapper {
        aspect-ratio: 1/1;

        .col_3 {
          width: 100%;

          video {
            border-radius: 0;
          }

          .status {
            border-radius: 0;
          }

          .panel {
            .top {
              .actions {
                flex-direction: row-reverse;

                .icon_btn {
                  margin-left: 0;
                  margin-right: 25px;
                }
              }

              .spacer {
                display: block;
              }

              .info {
                display: flex;
              }
            }

            .middle {
              .chat_box {
                display: flex;
              }

              video {
                display: flex;
                border-radius: 12px;
              }
            }

            .bottom {
              .c_input {
                display: flex;
              }

              .next_btn {
                font-size: 14px;
                margin: 0;
              }
            }
          }
        }

        .col_2 {
          display: none;
        }

      }

    }
  }
}

@media only screen and (max-width: 550px) {
  body {
    .documents {
      .doc_head {
        .wrapper {
          width: 85%;
        }
      }

      .doc_body {
        width: 85%;
      }
    }

    .footer {
      .wrapper {
        width: 85%;

        ul {
          flex-wrap: wrap;
        }

        .store_socials {
          .social {
            margin-top: 25px;
            flex-basis: 100%;
          }
        }
      }
    }

    //ABSOLUTE AREA
    .popup_content {
      align-items: flex-end;

      .gender {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        width: calc(100% - 40px);
      }

      .authentication {
        width: 100%;

        .left {
          display: none;
        }

        .right {
          padding: 20px 15px;
          width: 100%;
          border-top-left-radius: 15px;
          border-bottom-right-radius: 0;

          .info {
            margin-bottom: 15px;
          }
        }
      }
    }

    .permission_content {
      .box {
        left: 0;
        right: 0;
        align-items: center;
      }
    }

    .notify_content {
      width: 100%;
      right: 0;
      top: 0;

      .notify_box {
        margin: 15px;
        width: 100%;
      }
    }

    //ABSOLUTE AREA
  }

}